var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state.userOnModal
    ? _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": _vm.getModalClass },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeUserProfileAndCamera.apply(null, arguments)
            }
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("HeaderModal", {
                attrs: {
                  titleModal:
                    _vm.state.userOnModal.uuid == _vm.state.ownUUID
                      ? _vm.$t("components.userProfile.myUserInfo")
                      : _vm.amIAdmin
                      ? _vm.$t(
                          "components.userProfile.userInfo"
                        ) /* sentence with edit photo */
                      : _vm.$t(
                          "components.adminSettingsModal.userInfo"
                        ) /* user info */,
                  closeModalFunction: _vm.closeUserProfileAndCamera
                }
              }),
              _vm._v(" "),
              this.showCam
                ? _c("camera", {
                    attrs: {
                      getSnapshot: _vm.getSnapshot,
                      closeCamera: _vm.closeCamera
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showCroppie
                ? [
                    _c("UploadAvatarFunctionality", {
                      attrs: {
                        temporalAvatar: _vm.temporalAvatarUrl,
                        person: _vm.state.userOnModal,
                        showCroppie: _vm.showCroppie,
                        closeCroppie: _vm.openCloseCroppie,
                        resetInputAvatar: _vm.resetInputAvatar
                      }
                    })
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.showCroppie && !_vm.showCam
                ? _c(
                    "v-row",
                    {
                      staticClass: "pt-2 pb-4",
                      style: { width: "100%", margin: 0 },
                      attrs: { flex: "" }
                    },
                    [
                      _c("input", {
                        ref: "fileInput",
                        staticClass: "d-none",
                        attrs: {
                          type: "file",
                          accept: "image/x-png,image/gif,image/jpeg"
                        },
                        on: {
                          change: function($event) {
                            return _vm.showCroppieModal($event)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm._l(2, function(n) {
                        return _c(
                          "v-col",
                          {
                            key: n,
                            attrs: {
                              lg: _vm.cols[n - 1],
                              md: _vm.cols[n - 1],
                              sm: _vm.cols[n - 1],
                              xl: _vm.cols[n - 1]
                            }
                          },
                          [
                            n == 1
                              ? _c("div", { attrs: { align: "center" } }, [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        {
                                          staticClass:
                                            "videoSquareProfil borderRadius4 mx-0 my-0",
                                          attrs: { tile: "", size: "190" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "borderRadius4",
                                              staticStyle: {
                                                overflow: "hidden"
                                              }
                                            },
                                            [
                                              !_vm.showCameraPreview
                                                ? [
                                                    !_vm.showCam &&
                                                    _vm.state.userOnModal &&
                                                    _vm.getAvatarForUuid(
                                                      _vm.state.userOnModal.uuid
                                                    ) ===
                                                      "img/default_profile_picture.png"
                                                      ? _c("DefaultAvatar", {
                                                          attrs: {
                                                            size: 190,
                                                            userData:
                                                              _vm.state
                                                                .userOnModal
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !_vm.showCam &&
                                                    _vm.state.userOnModal &&
                                                    _vm.getAvatarForUuid(
                                                      _vm.state.userOnModal.uuid
                                                    ) !==
                                                      "img/default_profile_picture.png"
                                                      ? _c("v-img", {
                                                          staticClass:
                                                            "borderRadius4",
                                                          attrs: {
                                                            "max-height": "190",
                                                            "max-width": "190",
                                                            contain:
                                                              _vm.getPictureSize,
                                                            src: _vm.getAvatarForUuid(
                                                              _vm.state
                                                                .userOnModal
                                                                .uuid
                                                            ),
                                                            "lazy-src":
                                                              "assets/default_profile_picture.png"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ]
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.showCameraPreview
                                                ? [
                                                    _c("CameraPreview", {
                                                      attrs: {
                                                        width: 190,
                                                        closeCamera:
                                                          _vm.closeCameraPreview
                                                      }
                                                    })
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          (_vm.state.ownModalUser ||
                                            _vm.amIAdmin) &&
                                          !_vm.showCam &&
                                          !_vm.showCameraPreview
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "uploadAvatarButtons p-4 mx-auto",
                                                  class: {
                                                    hasImage:
                                                      _vm.state.user.avatar !=
                                                      "img/default_profile_picture.png",
                                                    hasNoImage:
                                                      _vm.state.user.avatar ==
                                                      "img/default_profile_picture.png"
                                                  },
                                                  attrs: { flex: "" }
                                                },
                                                [
                                                  _vm.getAvatarForUuid(
                                                    _vm.state.userOnModal.uuid
                                                  ) !==
                                                  "img/default_profile_picture.png"
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            depressed:
                                                                              "",
                                                                            color:
                                                                              "primary",
                                                                            isSmall:
                                                                              "true"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeAvatar()
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            style: {
                                                                              fontSize:
                                                                                "20px"
                                                                            },
                                                                            attrs: {
                                                                              icon: [
                                                                                "fal",
                                                                                "trash"
                                                                              ]
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.downloadsDropdown.delete"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.getAvatarForUuid(
                                                    _vm.state.userOnModal.uuid
                                                  ) !==
                                                  "img/default_profile_picture.png"
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            depressed:
                                                                              "",
                                                                            color:
                                                                              "primary",
                                                                            isSmall:
                                                                              "true"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.editAvatar()
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            style: {
                                                                              fontSize:
                                                                                "20px"
                                                                            },
                                                                            attrs: {
                                                                              icon: [
                                                                                "fal",
                                                                                "pen"
                                                                              ]
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.navbar.editPicture"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        depressed:
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                        isSmall:
                                                                          "true"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.selectAvatar()
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      {
                                                                        style: {
                                                                          fontSize:
                                                                            "20px"
                                                                        },
                                                                        attrs: {
                                                                          icon: [
                                                                            "fal",
                                                                            "upload"
                                                                          ]
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.navbar.uploadPicture"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        depressed:
                                                                          "",
                                                                        color:
                                                                          "primary",
                                                                        isSmall:
                                                                          "true"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.showCamFunc()
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      {
                                                                        style: {
                                                                          fontSize:
                                                                            "20px"
                                                                        },
                                                                        attrs: {
                                                                          icon: [
                                                                            "fal",
                                                                            "camera"
                                                                          ]
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.camera.takeSnapshot"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            n == 2
                              ? _c("div", { staticClass: "pt-2" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "userNameCard mt-1 mb-2 font-weight-medium",
                                      attrs: { color: "primary" }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.state.userOnModal.name) +
                                          "\n          "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.state.userOnModal.position
                                    ? _c(
                                        "p",
                                        { staticClass: "dataUserCard my-1" },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.state.userOnModal.position
                                              ) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.state.userOnModal.visitorData &&
                                  _vm.getLocationTranslated(
                                    _vm.state.userOnModal
                                  )
                                    ? _c(
                                        "p",
                                        { staticClass: "dataUserCard my-1" },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.getLocationTranslated(
                                                  _vm.state.userOnModal
                                                )
                                              ) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.amIPrivileges &&
                                  _vm.state.userOnModal.email
                                    ? _c(
                                        "p",
                                        { staticClass: "dataUserCard my-1" },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.state.userOnModal.email
                                              ) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.amIPrivileges &&
                                  _vm.state.userOnModal.phone
                                    ? _c(
                                        "p",
                                        { staticClass: "dataUserCard my-1" },
                                        [
                                          _vm._v(
                                            "\n            Tel.: " +
                                              _vm._s(
                                                _vm.state.userOnModal.phone
                                              ) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasRepresentative(
                                    _vm.state.userOnModal.uuid
                                  )
                                    ? _c(
                                        "p",
                                        { staticClass: "dataUserCard my-1" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "fontWeight500" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.representative.representative"
                                                  )
                                                ) + ": "
                                              )
                                            ]
                                          ),
                                          _vm._v(
                                            _vm._s(_vm.representativeName) +
                                              "\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _vm.getOrgaInfoDeps ||
                      _vm.getOrgaInfoTeams ||
                      (_vm.showAdditionalInfo && !_vm.showCroppie)
                        ? _c(
                            "v-col",
                            {
                              staticClass: "px-8 pb-0 pt-4 additionalInfoClass"
                            },
                            [
                              _vm.getOrgaInfoDeps
                                ? _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "dataUserCard my-1 fontWeight500"
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.organisationCarousel.departments"
                                              )
                                            ) +
                                            ":\n          "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "dataUserCard my-1" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.getOrgaInfoDeps) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.getOrgaInfoTeams
                                ? _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "dataUserCard my-1 fontWeight500"
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.organisationCarousel.teams"
                                              )
                                            ) +
                                            ":\n          "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "dataUserCard my-1" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.getOrgaInfoTeams) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showAdditionalInfo
                                ? [
                                    _vm.state.userOnModal.userSettings
                                      .additionalStartDate
                                      ? _c("div", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "dataUserCard my-1 fontWeight500"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.userSettings.startDate"
                                                    )
                                                  ) +
                                                  ":\n              "
                                              ),
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "min-width": "290px"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _vm.state
                                                              .userOnModal
                                                              .uuid ==
                                                            _vm.state.ownUUID
                                                              ? _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "editButtons",
                                                                        attrs: {
                                                                          icon:
                                                                            ""
                                                                        }
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      {
                                                                        style: {
                                                                          fontSize:
                                                                            "15px"
                                                                        },
                                                                        attrs: {
                                                                          icon: [
                                                                            "fal",
                                                                            "pen"
                                                                          ]
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3667001531
                                                  ),
                                                  model: {
                                                    value: _vm.startDateMenu,
                                                    callback: function($$v) {
                                                      _vm.startDateMenu = $$v
                                                    },
                                                    expression: "startDateMenu"
                                                  }
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("v-date-picker", {
                                                    attrs: {
                                                      "no-title": "",
                                                      scrollable: ""
                                                    },
                                                    on: {
                                                      change: function() {
                                                        return (_vm.state.user.userSettings.additionalStartDate =
                                                          _vm.state.userOnModal.userSettings.additionalStartDate)
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.state.userOnModal
                                                          .userSettings
                                                          .additionalStartDate,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.state.userOnModal
                                                            .userSettings,
                                                          "additionalStartDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "state.userOnModal.userSettings.additionalStartDate"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "dataUserCard my-1"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm
                                                      .moment(
                                                        _vm.state.userOnModal
                                                          .userSettings
                                                          .additionalStartDate
                                                      )
                                                      .format("DD-MM-YYYY")
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.state.userOnModal.userSettings
                                      .educationBackground
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "dataUserCard my-0 fontWeight500"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.userSettings.educationBackground"
                                                      )
                                                    ) +
                                                    ":\n                "
                                                ),
                                                !_vm.editEducation &&
                                                _vm.state.userOnModal.uuid ==
                                                  _vm.state.ownUUID
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "editButtons",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.changeEditEducation()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "15px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fal",
                                                                "pen"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.editEducation
                                                  ? [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "editButtons",
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.saveAdditionalUserInfo(
                                                                "educationBackground_info"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "15px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "save"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "editButtons",
                                                          attrs: {
                                                            icon: "",
                                                            disabled:
                                                              _vm.educationBackground_info.trim() ===
                                                              ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeEditEducation()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "15px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "times"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _vm.editEducation
                                              ? _c("v-text-field", {
                                                  attrs: {
                                                    "hide-details": "",
                                                    dense: "",
                                                    flat: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.educationBackground_info,
                                                    callback: function($$v) {
                                                      _vm.educationBackground_info = $$v
                                                    },
                                                    expression:
                                                      "educationBackground_info"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.editAdditionalKnowledge
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "dataUserCard my-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.educationBackground_info
                                                        ) +
                                                        "\n              "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.state.userOnModal.userSettings
                                      .additionalKnowledge
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "dataUserCard my-1 fontWeight500"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.userSettings.additionalKnowledge"
                                                      )
                                                    ) +
                                                    ":\n                "
                                                ),
                                                !_vm.editAdditionalKnowledge &&
                                                _vm.state.userOnModal.uuid ==
                                                  _vm.state.ownUUID
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "editButtons",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.changeAdditionalKnowledge()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "15px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fal",
                                                                "pen"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.editAdditionalKnowledge
                                                  ? [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "editButtons",
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.saveAdditionalUserInfo(
                                                                "additionalKnowledge_info"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "15px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "save"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "editButtons",
                                                          attrs: {
                                                            icon: "",
                                                            disabled:
                                                              _vm.additionalKnowledge_info.trim() ===
                                                              ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeAdditionalKnowledge()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "15px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "times"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _vm.editAdditionalKnowledge
                                              ? _c("v-text-field", {
                                                  attrs: {
                                                    "hide-details": "",
                                                    dense: "",
                                                    flat: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.additionalKnowledge_info,
                                                    callback: function($$v) {
                                                      _vm.additionalKnowledge_info = $$v
                                                    },
                                                    expression:
                                                      "additionalKnowledge_info"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.editAdditionalKnowledge
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "dataUserCard my-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.additionalKnowledge_info
                                                        ) +
                                                        "\n              "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.state.userOnModal.userSettings
                                      .additionalBriefDesc
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "dataUserCard my-1 fontWeight500"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.userSettings.briefDesc"
                                                      )
                                                    ) +
                                                    ":\n                "
                                                ),
                                                !_vm.editAdditionalBriefDesc &&
                                                _vm.state.userOnModal.uuid ==
                                                  _vm.state.ownUUID
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "editButtons",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.changeAdditionalBriefDesc()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "15px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fal",
                                                                "pen"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.editAdditionalBriefDesc
                                                  ? [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "editButtons",
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.saveAdditionalUserInfo(
                                                                "additionalBriefDesc_info"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "15px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "save"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "editButtons",
                                                          attrs: {
                                                            icon: "",
                                                            disabled:
                                                              _vm.additionalBriefDesc_info.trim() ===
                                                              ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeAdditionalBriefDesc()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "15px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "times"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _vm.editAdditionalBriefDesc
                                              ? _c("v-text-field", {
                                                  attrs: {
                                                    "hide-details": "",
                                                    dense: "",
                                                    flat: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.additionalBriefDesc_info,
                                                    callback: function($$v) {
                                                      _vm.additionalBriefDesc_info = $$v
                                                    },
                                                    expression:
                                                      "additionalBriefDesc_info"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.editAdditionalBriefDesc
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "dataUserCard my-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.additionalBriefDesc_info
                                                        ) +
                                                        "\n              "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.state.userOnModal.userSettings
                                      .additionalWorkExp
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "dataUserCard my-1 fontWeight500"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.userSettings.workExp"
                                                      )
                                                    ) +
                                                    ":\n                "
                                                ),
                                                !_vm.editAdditionalWorkExp &&
                                                _vm.state.userOnModal.uuid ==
                                                  _vm.state.ownUUID
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "editButtons",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.changeAdditionalWorkExp()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "15px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fal",
                                                                "pen"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.editAdditionalWorkExp
                                                  ? [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "editButtons",
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.saveAdditionalUserInfo(
                                                                "additionalWorkExp_info"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "15px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "save"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "editButtons",
                                                          attrs: {
                                                            icon: "",
                                                            disabled:
                                                              _vm.additionalWorkExp_info.trim() ===
                                                              ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeAdditionalWorkExp()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "15px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "times"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _vm.editAdditionalWorkExp
                                              ? _c("v-text-field", {
                                                  attrs: {
                                                    "hide-details": "",
                                                    dense: "",
                                                    flat: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.additionalWorkExp_info,
                                                    callback: function($$v) {
                                                      _vm.additionalWorkExp_info = $$v
                                                    },
                                                    expression:
                                                      "additionalWorkExp_info"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.editAdditionalWorkExp
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "dataUserCard my-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                " +
                                                        _vm._s(
                                                          _vm.additionalWorkExp_info
                                                        ) +
                                                        "\n              "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.state.userOnModal.userSettings
                                      .additionalInterests
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "dataUserCard my-1 fontWeight500"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.userSettings.interests"
                                                      )
                                                    ) +
                                                    ":\n                "
                                                ),
                                                !_vm.editAdditionalInterests &&
                                                _vm.state.userOnModal.uuid ==
                                                  _vm.state.ownUUID
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "editButtons",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.changeaAdditionalInterests()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "15px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fal",
                                                                "pen"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.editAdditionalInterests
                                                  ? [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "editButtons",
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.saveAdditionalUserInfo(
                                                                "additionalInterests_info"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "15px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "save"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "editButtons",
                                                          attrs: {
                                                            icon: "",
                                                            disabled:
                                                              _vm.additionalInterests_info.trim() ===
                                                              ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.changeaAdditionalInterests()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              style: {
                                                                fontSize: "15px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "times"
                                                                ]
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _vm.editAdditionalInterests
                                              ? _c("v-text-field", {
                                                  attrs: {
                                                    "hide-details": "",
                                                    dense: "",
                                                    flat: ""
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.additionalInterests_info,
                                                    callback: function($$v) {
                                                      _vm.additionalInterests_info = $$v
                                                    },
                                                    expression:
                                                      "additionalInterests_info"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.editAdditionalInterests
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "dataUserCard my-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n              " +
                                                        _vm._s(
                                                          _vm.additionalInterests_info
                                                        ) +
                                                        "\n            "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.showCroppie && !_vm.showCam
                ? [
                    _c(
                      "FooterModal",
                      {
                        attrs: {
                          closeModalFunction: _vm.closeUserProfileAndCamera,
                          showFooter: !_vm.showCroppie && !_vm.showCam
                        }
                      },
                      [
                        !_vm.state.ownModalUser
                          ? _c(
                              "div",
                              { staticClass: "px-0 py-0" },
                              [
                                !_vm.isInCallWithUs
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        class:
                                                          "ml-2 buttonIconPrimaryFooter " +
                                                          (_vm.disableCallBtn
                                                            ? "buttonIconPrimaryFooterDisable"
                                                            : ""),
                                                        style:
                                                          " " +
                                                          (_vm.disableCallBtn
                                                            ? "color: #11889d!important;"
                                                            : ""),
                                                        attrs: {
                                                          icon: "",
                                                          tile: "",
                                                          color: "primary",
                                                          disabled:
                                                            _vm.disableCallBtn
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.callUser(
                                                              _vm.state
                                                                .userOnModal
                                                                .uuid
                                                            )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          fontSize: "20px"
                                                        },
                                                        attrs: {
                                                          icon: ["fal", "phone"]
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1619394870
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.userProfile.callUser"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isInCallWithUs
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "ml-2 buttonIconPrimaryFooter",
                                                        attrs: {
                                                          color: "primary",
                                                          icon: "",
                                                          tile: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm
                                                              .getWebrtcConnections()
                                                              [
                                                                _vm.state
                                                                  .userOnModal
                                                                  .uuid
                                                              ].die(true)
                                                            _vm.closeUserProfileAndCamera()
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          fontSize: "20px"
                                                        },
                                                        attrs: {
                                                          icon: [
                                                            "fal",
                                                            "phone"
                                                          ],
                                                          rotation: 270
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3761525929
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("generics.hangUp"))
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "ml-2 buttonIconPrimaryFooter",
                                                    attrs: {
                                                      icon: "",
                                                      tile: "",
                                                      color: "primary"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setShowModalSenMsg(
                                                          _vm.state.userOnModal
                                                            .uuid
                                                        )
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: { fontSize: "20px" },
                                                    attrs: {
                                                      icon: [
                                                        "fal",
                                                        "paper-plane"
                                                      ]
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2702662442
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.userProfile.sendAMessage"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _vm.state.ownModalUser
                                        ? _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "ml-2 buttonIconPrimaryFooter",
                                                attrs: {
                                                  icon: "",
                                                  tile: "",
                                                  color: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.cameraPreview(
                                                      !_vm.showCameraPreview
                                                    )
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "20px" },
                                                attrs: {
                                                  icon: ["fal", "video"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2430225972
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.userProfile.videoTest")
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }